// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-button {
    width: auto;
    display: flex;
    height: auto;
    background: #FFFFFF !important;
    border-radius: 2px !important;
    font-family: 'Sofia Pro Light' !important;
    font-style: normal;
    border: 1px solid #C59531 !important;
    font-weight: 300;
    font-size: 16px !important;
    line-height: 16px;
    color: #001F5F !important;
    position: relative;
    padding: 12px 24px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-image: linear-gradient(270deg, #C59531 0%, #FEEAB8 50%, #C59531 100%);
    }

    &:hover {
        background: #C59531 !important;
        color: #FFFFFF !important;
    }

    &.selected-button {
        background: #C59531 !important;
        color: white !important;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/OptionButton/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,8BAA8B;IAC9B,6BAA6B;IAC7B,yCAAyC;IACzC,kBAAkB;IAClB,oCAAoC;IACpC,gBAAgB;IAChB,0BAA0B;IAC1B,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;;IAEf;QACI,WAAW;QACX,kBAAkB;QAClB,SAAS;QACT,OAAO;QACP,WAAW;QACX,WAAW;QACX,gFAAgF;IACpF;;IAEA;QACI,8BAA8B;QAC9B,yBAAyB;IAC7B;;IAEA;QACI,8BAA8B;QAC9B,uBAAuB;IAC3B;;AAEJ","sourcesContent":[".quiz-button {\n    width: auto;\n    display: flex;\n    height: auto;\n    background: #FFFFFF !important;\n    border-radius: 2px !important;\n    font-family: 'Sofia Pro Light' !important;\n    font-style: normal;\n    border: 1px solid #C59531 !important;\n    font-weight: 300;\n    font-size: 16px !important;\n    line-height: 16px;\n    color: #001F5F !important;\n    position: relative;\n    padding: 12px 24px;\n    cursor: pointer;\n\n    &::after {\n        content: '';\n        position: absolute;\n        bottom: 0;\n        left: 0;\n        width: 100%;\n        height: 3px;\n        background-image: linear-gradient(270deg, #C59531 0%, #FEEAB8 50%, #C59531 100%);\n    }\n\n    &:hover {\n        background: #C59531 !important;\n        color: #FFFFFF !important;\n    }\n\n    &.selected-button {\n        background: #C59531 !important;\n        color: white !important;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
