import React, { useEffect, useState } from 'react';
import { Carousel } from 'primereact/carousel';
import { Box, Card, CardMedia, Typography } from '@mui/material';
import './index.css';

const CarouselProdutcs = () => {

    const [hasQrcode, setHasQrcode] = useState(false);
    const [isFromMexico, setIsFromMexico] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const userLanguage = navigator.language || navigator.userLanguage;
        setHasQrcode(queryParams.has('qrcode'));
        setIsFromMexico((userLanguage.startsWith('es-MX')) || (userLanguage.startsWith('pt-BR')));
    }, []);

    // Refactor LINKS - Harisson
    function getCountry() {
        const userLanguage = navigator.language || navigator.userLanguage;

        if (userLanguage.startsWith('es-MX')) return 'mexico';
        if (userLanguage.startsWith('es-AR')) return 'argentina';
        if (userLanguage.startsWith('es-CO')) return 'colombia';
        if (userLanguage.startsWith('pt-BR')) return 'mexico';
    }

    const countryLinks = {
        mexico: {
            shopping: 'https://www.amazon.com.mx/stores/page/6F4E9D65-EF04-4A40-82DA-B40DEB23EE6C',
        },
        argentina: {
            shopping: 'https://www.dove.com/ar/home.html',
        },
        colombia: {
            shopping: 'https://www.dove.com/co/cremas-corporales.html',
        }
    };

    const country = getCountry();
    const links = countryLinks[country];
    // Refactor LINKS - Harisson

    const products = [
        {
            name: 'SÉRUM CONCENTRADO',
            description: 'Respalda la producción de antioxidantes, ayuda a restablecer la firmeza de la piel, reduce la apariencia de estrías, manchas y cicatrices, además de mejorar la elasticidad y prevenir daños futuros. ',
            image: 'images/carousel/serum.png',
            linkShopping: 'https://www.amazon.com.mx/Concentrado-Cicatrices-Elasticidad-regeneraci%C3%B3n-hidrataci%C3%B3n/dp/B0CTLJ57JH?ref_=ast_sto_dp',
        },
        {
            name: 'NIACINAMIDA',
            description: 'Enriquecido con vitamina C y aceite de rosa mosqueta 100% natural, ayuda a unificar el tono de la piel, reduciendo las manchas oscuras causadas por la exposición al sol. ',
            image: 'images/carousel/niacinamida.png',
            linkShopping: 'https://www.amazon.com.mx/Corporal-Niacinamida-Uniforme-obscuras-resequedad/dp/B0CTLN2ZS6?ref_=ast_sto_dp',
        },
        {
            name: 'HIALURÓNICO',
            description: 'Con colágeno vegano, pro-lípidos y niacinamida, ayuda a fortalecer la barrera cutánea y estimula la renovación celular, protegiendo contra 5 signos de resequedad. ',
            image: 'images/carousel/hialuronico.png',
            linkShopping: 'https://www.amazon.com.mx/Corporal-Renovador-Hialur%C3%B3nico-previene-resequedad/dp/B0CTLNC9TM?ref_=ast_sto_dp',
        },
        {
            name: 'PRO-CERAMIDAS',
            description: 'Restaurar la humectación natural de la piel desde el primer uso, devolviendo la suavidad y luminosidad, con una tecnología que combina colágeno, glicerina y pantenol. ',
            image: 'images/carousel/pro-ceramidas.png',
            linkShopping: 'https://www.amazon.com.mx/Corporal-Hidratante-Pro-Ceramidas-recupera-luminosidad/dp/B0CTLRD8H4?ref_=ast_sto_dp',
        },
        {
            name: 'PANTENOL',
            description: 'Protege contra la resequedad y los factores agresivos externos, promoviendo firmeza, soporte y resistencia de la piel extra seca, con hasta 72 horas de humectación regenerativa. ',
            image: 'images/carousel/pantenol.png',
            linkShopping: 'https://www.amazon.com.mx/Corporal-Pantenol-Regenerador-sensible-humectaci%C3%B3n/dp/B0CTLWNV8D?ref_=ast_sto_dp',
        },
        {
            name: 'PRO-RETINOL',
            description: 'El Pro-Retinol tiene poderosa acción reafirmante. Actúa en la regeneración de la piel, reduciendo y minimizando los daños causados por el tiempo. ',
            image: 'images/carousel/pro-retinol.png',
            linkShopping: 'https://www.amazon.com.mx/Corporal-Reafirmante-Pro-Retinol-recupera-elasticidad/dp/B0CTLH41PM?ref_=ast_sto_dp',
        },
        {
            name: 'BÁLSAMO CONCENTRADO',
            description: 'Con tecnología avanzada, el Bálsamo Concentrado es un tratamiento profundo para reparar, fortalecer, regenerar e hidratar la piel de los talones agrietados. ',
            image: 'images/carousel/balsamo.png',
            linkShopping: 'https://www.amazon.com.mx/Concentrado-Agrietados-Tratamiento-descamaci%C3%B3n-aspereza/dp/B0CTLFZ4NY?ref_=ast_sto_dp',
        }
    ]


    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3.5,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3.5,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2.5,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1.2,
            numScroll: 1
        }
    ];


    const productTemplate = (product) => {
        return (
            <Card elevation={0} className='card-carousel' >
                <CardMedia component="img" height="140" image={product.image} alt={product.name} />
                <Box flexDirection='column' display='flex' alignItems='center'>
                    <Typography variant="h6" component="h3" className="title" align="center">{product.name}</Typography>
                    <Typography variant="body2" className="subtitle" mb={3} align="center">{product.description}</Typography>
                    {(!hasQrcode && isFromMexico) && (
                        <button onClick={() => window.open(product.linkShopping, '_blank')} className='quiz-button'>Compre ahora</button>
                    )}
                </Box>
            </Card>
        );
    };

    return (
        <div>
            <h2 className='title'>Conoce la línea completa</h2>
            <Typography variant="body2" className='paragraph'>Clínicamente probada para reducir marcas oscuras en 2 semanas </Typography>
            <Carousel value={products} numScroll={1} numVisible={3.1} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
        </div>
    )
}
export default CarouselProdutcs;