import React from 'react';

import './index.css'

const OptionButton = ({ option, onClick }) => {
    return (
        <button
            className='quiz-button'
            onClick={onClick}
        >
            {option}
        </button>
    );
};

export default OptionButton;