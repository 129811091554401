import React from 'react';

import Banner from "../components/Banner";
import Cards from "../components/Cards";
import CarouselProdutcs from "../components/CarouselProdutcs";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import QuizChat from "../components/QuizChat";

const Page = () => {
    return (
        <>
            <Navbar />
            <main>
                <Banner />
                <Cards />
                <QuizChat />
                <CarouselProdutcs/>
            </main>
            <Footer/>

        </>
    )
}
export default Page;