import React, { useEffect, useState } from 'react';
import Page from './Page';
import Error404 from './Page/Error404';
import './fonts/sofia-pro/style.css';

const App = () => {

  const [validPath, setValidPath] = useState(true);

  useEffect(() => {
    const validPaths = ['/co', '/ar', '/mx', '/CO', '/AR', '/MX'];
    const path = window.location.pathname;

    if (!validPaths.includes(path)) {
      setValidPath(false);
    } else {
      setValidPath(true);
    }
  }, []);

  return (
    <div>

      {validPath && (
        <Page />
      )}

      {!validPath && (
        <Error404 />
      )}

      {/* <Page /> */}

    </div>
  );
}

export default App;