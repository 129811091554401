import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid, Container, Box, Card, CardMedia, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import './index.css';
import questions from './questions.json';
import ChatBubble from '../ChatBubble';
import OptionButton from '../OptionButton';

const QuizChat = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [conversation, setConversation] = useState([{ text: questions.steps[0].text, sender: 'bot', card: false }]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [userName, setUserName] = useState('');
  const [nameSubmitted, setNameSubmitted] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false)
  const [isInactive, setIsInactive] = useState(false);
  const [buyButtonClicked, setBuyButtonClicked] = useState(false)

  const [hasQrcode, setHasQrcode] = useState(false);

  const userLanguage = navigator.language || navigator.userLanguage;
  const isFromMexico = userLanguage.startsWith('es-MX') || userLanguage.startsWith('pt-BR');

  // Refactor LINKS - Harisson
  function getCountry() {
    const userLanguage = navigator.language || navigator.userLanguage;

    if (userLanguage.startsWith('es-MX')) return 'mexico';
    if (userLanguage.startsWith('es-AR')) return 'argentina';
    if (userLanguage.startsWith('es-CO')) return 'colombia';
  }

  const countryLinks = {
    mexico: {
      accessLP: 'http://www.analiza-tu-piel.dove.com/MX',
    },
    argentina: {
      accessLP: 'https://www.analiza-tu-piel.dove.com/AR',
    },
    colombia: {
      accessLP: 'https://www.analiza-tu-piel.dove.com/CO',
    }
  };

  const country = getCountry();
  const links = countryLinks[country];
  // Refactor LINKS - Harisson

  const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutos

  const replaceNamePlaceholders = (text) => {
    return text.replace('@name@', `<strong>${userName}</strong>`).replace(/\n/g, '<br />');
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setHasQrcode(queryParams.has('qrcode'));
  }, []);

  useEffect(() => {
    const resetTimer = () => {
      setIsInactive(false);
      clearTimeout(window.inactivityTimeout);
      window.inactivityTimeout = setTimeout(() => {
        setIsInactive(true);
      }, INACTIVITY_TIMEOUT);
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    resetTimer(); // Inicia o timer quando o componente é montado

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      clearTimeout(window.inactivityTimeout);
    };
  }, [INACTIVITY_TIMEOUT]);

  const handleSubmitName = () => {
    if (userName.trim() !== '') {
      const message = { text: userName.trim(), sender: 'user' };
      const newConversation = [...conversation, message];
      setConversation(newConversation);
      setNameSubmitted(true);
      const nextQuestionIndex = currentQuestionIndex + 1;

      const botMessage = { text: replaceNamePlaceholders(questions.steps[nextQuestionIndex].text), sender: 'bot' };
      setConversation([...newConversation, botMessage]);

      if (nextQuestionIndex < questions.steps.length) {
        setTimeout(() => {
          setConversation([...newConversation, botMessage]);
          setCurrentQuestionIndex(nextQuestionIndex);
        }, 900);
      }
    }
  };

  const handleSelectedCheckbox = (event, newSelectedOptions) => {
    if (newSelectedOptions.length === 0) {
      setSelectedOptions([]);
      setSelectedCount(0);
      return;
    }

    let updatedOptions;

    if (currentQuestionIndex === 6) {
      if (newSelectedOptions.length <= 2) {
        updatedOptions = newSelectedOptions;
      } else {
        return;
      }
    } else {
      updatedOptions = newSelectedOptions;
    }

    setSelectedOptions(updatedOptions);
    setSelectedCount(updatedOptions.length);
  };

  const handleOptionSelect = (option) => {
    const message = { text: option.text || option, sender: 'user' };
    const newConversation = [...conversation, message];

    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex < questions.steps.length) {
      setTimeout(() => {
        const botMessage = { text: questions.steps[nextQuestionIndex].text, sender: 'bot' };
        setConversation([...newConversation, botMessage]);
        setCurrentQuestionIndex(nextQuestionIndex);
      }, 900);
    }
  };

  const getResult = () => {
    const lastUserMessageObj = conversation.filter(sender => sender.sender === 'user').slice(-1)[0];
    const lastUserMessage = lastUserMessageObj ? lastUserMessageObj.text : '';
    const selectedOptionsText = selectedOptions.sort().join(', ');

    if (questions.results[lastUserMessage] && questions.results[lastUserMessage][selectedOptionsText]) {
      const scenario = questions.results[lastUserMessage][selectedOptionsText];
      return questions.scenarios[scenario] || [];
    } else {
      console.error('Combinação de opções selecionadas não encontrada no JSON');
      return [];
    }
  };


  const handleConfirmOptions = () => {
    if (currentQuestionIndex === 6) {
      if (selectedCount > 0 && selectedCount <= 2) {
        const selectedResponses = selectedOptions.map(option => option);
        const personalizedResponse = selectedResponses.join('\n y \n');

        const message = { text: personalizedResponse, sender: 'user' };
        const newConversation = [...conversation, message];
        setConversation(newConversation);

        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questions.steps.length) {
          setTimeout(() => {
            const botMessage = { text: questions.steps[nextQuestionIndex].text, sender: 'bot' };
            setConversation([...newConversation, botMessage]);
            setCurrentQuestionIndex(nextQuestionIndex);
          }, 900);
        } else {
          const result = getResult();
          const updatedConversation = [...newConversation];

          result.forEach((step) => {
            switch (step.type) {
              case 'text':
                updatedConversation.push({
                  text: step.text,
                  sender: 'bot'
                });
                break;
              case 'products':
                /*const product = step.options[0];
                updatedConversation.push({
                  text: step.text,
                  title: product,
                  image: questions.products[product]?.image,
                  description: questions.products[product]?.text,
                  url: questions.products[product]?.url,
                  sender: 'bot',
                  card: true,
                  noHover: true,
                });*/

                // Refactor QUIZ LP - Harisson
                if (step.options && Array.isArray(step.options)) {
                  const cardLength = step.options.length;
                  console.log('cardLength :: ', cardLength);
                  for (let i = 0; i < step.options.length; i++) {
                    console.log('i :: ', i);
                    const product = step.options[i];
                    updatedConversation.push({
                      text: step.text,
                      title: product,
                      image: questions.products[product]?.image,
                      description: questions.products[product]?.text,
                      url: questions.products[product]?.url,
                      sender: 'bot',
                      card: true,
                      noHover: true,
                      displayChatBuble: i > 0 ? false : true
                    });
                  }
                }
                // Refactor QUIZ LP - Harisson
                break;
              default:
                break;
            }
          });

          setConversation(updatedConversation);
          setDisableButtons(true);
        }
      }
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const handleBeforeUnload = (event) => {
      const result = getResult();
      let produtoUm = '';
      let produtoDois = '';

      if (result.length > 0) {
        const products = result.filter(step => step.type === 'products').flatMap(step => step.options);
        if (products.length > 0) {
          produtoUm = products[0];
          produtoDois = products.length > 1 ? products[1] : '';
        }
      }

      const url = 'https://ms-quiz-dove-backend-xnskcjc7bq-uc.a.run.app/api/telemetria';
      const data = JSON.stringify({
        url: currentUrl,
        isFinal: currentQuestionIndex === questions.steps.length - 1,
        etapas: currentQuestionIndex,
        //botaoExposto: isFromMexico,
        botaoExposto: hasQrcode ? false : true,
        isEcommerce: buyButtonClicked,
        produtoUm: produtoUm,
        produtoDois: produtoDois
      });

      // Usando sendBeacon para enviar a requisição POST
      navigator.sendBeacon(url, data);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  return (
    <div className='bckg-image'>
      <Container id="quiz" sx={{ margin: 'auto', padding: '20px', textAlign: 'center', maxWidth: '1000px !important' }}>
        <Paper elevation={1} sx={{ minHeight: '535px', padding: '29px', border: '1px solid #C59531', borderRadius: '16px !important', height: 'auto' }}>
          <img src="./images/bird.svg" alt="bird logo" />
          <Box mt={2} mb={4} sx={{ gap: '12px', display: 'flex', flexDirection: 'column' }}>
            <Typography className="quiz-title" variant="h6" component="h2">Test de piel</Typography>
            <Typography className="quiz-subtitle" variant="body2">¡Comencemos tu camino hacia una piel regenerada y radiante!</Typography>
          </Box>
          <Box>
            {conversation.map((message, index) => (
              <Box key={index}>
                {message.card ? (
                  <Box mb={5}>

                    {message.displayChatBuble && (
                      <ChatBubble text={replaceNamePlaceholders(message.text)} sender={message.sender} />
                    )}

                    <Card md={8} elevation={0} className={`card-quiz ${message.noHover ? 'no-hover' : ''}`} sx={{ height: 'auto', width: { sm: '55% !important', xs: '80% !important' } }}>
                      <CardMedia component="img" sx={{ height: '200px', borderRadius: '8.62px' }} image={message.image} alt={message.title} />
                      <Box>
                        <Typography sx={{ textTransform: 'uppercase', fontFamily: "Sofia Pro Light" }} variant="h6" component="h3" className="card-quiz-title">{message.title}</Typography>
                        <Typography sx={{ fontFamily: "Sofia Pro Light", marginBottom: '24px' }} variant="body2" className="card-quiz-subtitle">{message.description}</Typography>
                        {isFromMexico && (
                          <a href={message.url} target="_blank" rel="noopener noreferrer">
                            <button className='quiz-button' onClick={() => setBuyButtonClicked(true)}>Comprar ahora</button>
                          </a>
                        )}
                      </Box>
                    </Card>
                  </Box>
                ) : (
                  <ChatBubble text={replaceNamePlaceholders(message.text)} sender={message.sender} />
                )}
                {isInactive && (
                  <>
                    <ChatBubble text='Oops... Parece que te desconectaste y el test se interrumpió. Pero, puedes volver cuando quieras y descubrir la rutina perfecta para tu piel con los Sérums Dove 😊' sender='bot' />
                    <ChatBubble text='¡Hasta luego! 👋' sender='bot' />
                  </>
                )}
              </Box>
            ))}
            <Grid container spacing={2} ml={{ xs: '0px', sm: `${conversation[currentQuestionIndex].sender === 'bot' ? '40px' : '0px'}` }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
              {currentQuestionIndex === 1 ? (
                <Box display="grid" gap={2} flex='auto' justifyContent='end'>
                  <Box flexDirection='row-reverse' display='flex' mr={2}>
                    <img className='avatar-user' alt='avatar' src='/images/CircleUser.svg' />
                    <Box position='relative'>
                      <TextField
                        autoFocus
                        variant="outlined"
                        fullWidth
                        value={userName}
                        onKeyDown={(e) => { if (e.key === 'Enter') handleSubmitName(); }}
                        onChange={(e) => setUserName(e.target.value)}
                        disabled={nameSubmitted}
                        className="user-chat-bubble-input"
                      />
                      <div className='chat-bubble-tip-right' />
                    </Box>
                  </Box>
                  <button onClick={handleSubmitName} className='user-chat-bubble-button' disabled={!userName}>
                    Confirmar
                  </button>
                </Box>
              ) : currentQuestionIndex === 6 ? (
                !disableButtons && (
                  <Box>
                    <ToggleButtonGroup className='toggle-buttons' value={selectedOptions} onChange={handleSelectedCheckbox} aria-label="Opções">
                      {questions.steps[6].options.map((option, index) => (
                        <ToggleButton key={index} value={option} sx={{ textTransform: 'math-auto', padding: '6px 16px 4px 16px', '&::after': { background: 'none' } }} className={`quiz-button ${selectedOptions.includes(option) ? 'selected-button' : ''}`}>
                          {option}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    <Typography sx={{ marginBottom: '9px', color: '#001F5F', fontFamily: "Sofia Pro Light", fontSize: '16px', fontWeight: '700' }} variant="body2" textAlign='left'>
                      Haga clic en el botón abajo para continuar:
                    </Typography>
                    <button className='quiz-button' disabled={selectedCount < 1} onClick={handleConfirmOptions}>
                      Confirmar
                    </button>
                  </Box>
                )
              ) : (
                questions.steps[currentQuestionIndex].options.map((option, index) => (
                  <Grid item key={index}>
                    {questions.steps[currentQuestionIndex].type === 'cards' ? (
                      <Card onClick={() => handleOptionSelect(option)} elevation={0} className='card-quiz'>
                        <CardMedia component="img" height="140" image={questions.cards[option].image} alt={questions.cards[option].text} />
                        <Box>
                          <Typography variant="h6" component="h3" sx={{ textTransform: 'uppercase', fontFamily: "Sofia Pro Light" }} className="card-quiz-title" align="center">{option}</Typography>
                          <Typography variant="body2" sx={{ fontFamily: "Sofia Pro Light" }} className="card-quiz-subtitle" align="center">{questions.cards[option].text}</Typography>
                        </Box>
                      </Card>
                    ) : (
                      <OptionButton option={option} onClick={() => handleOptionSelect(option)} />
                    )}
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default QuizChat;